import React from "react"

const Middle = ({ title }) => {
  return (
    <div class="akc-middle">
      <h2>{title}</h2>
    </div>
  )
}

export default Middle
