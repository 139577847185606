import React from "react"
import Layout from "../components/layout"
import Middle from "../components/Middle"
import uros from "../img/aikido-uros.jpg"
import tilen from "../img/aikido-tilen.jpg"
import dean from "../img/aikido-dean.jpg"
import miran from "../img/aikido-miran.jpg"
import luc from "../img/aikido-luc.jpg"
import simon from "../img/aikido-simon.jpg"
import muros from "../img/muros1.jpg"
import bane from "../img/aikido-bane.jpg"
import bostjan from "../img/aikido-bostjan.jpg"
import dejan from "../img/aikido-dean.jpg"
import klemen from "../img/aikido-klemen.jpg"
import dummy from "../img/aikido-dummy.jpg"
const mojstri = () => {
  const edges = [
    {
      title: "Uroš Jezernik",
      pas: "Črni pas, 5. DAN",
      text: "Predsednik in glavni trener kluba",
      imageUrl: uros,
    },
    {
      title: "Uroš Počivavšek",
      pas: "Črni pas, 3. DAN",
      text: "Trener kluba",
      imageUrl: muros,
    },
    {
      title: "Boštjan Jurkošek",
      pas: "Črni pas, 2. DAN",
      text: "Član kluba",
      imageUrl: bostjan,
    },

    {
      title: "Bane Nikolić",
      pas: "Črni pas, 1. DAN",
      text: "Član kluba",
      imageUrl: bane,
    },
    {
      title: "Miran Kostanjevec",
      pas: "Črni pas, 3. DAN",
      text: "Član kluba",
      imageUrl: miran,
    },
    {
      title: "Hilarij Pongrašič Lars",
      pas: "Črni pas, 1. DAN",
      text: "Član kluba",
      imageUrl: dummy,
    },
    {
      title: "Mensud Memić",
      pas: "Črni pas, 1. DAN",
      text: "Član kluba",
      imageUrl: dummy,
    },
    {
      title: "Klemen Kerstein",
      pas: "Črni pas, 1. DAN",
      text: "Član kluba",
      imageUrl: klemen,
    },
    {
      title: "Dean Dobrilovič",
      pas: "Črni pas, 1. DAN",
      text: "Član kluba",
      imageUrl: dean,
    },
    {
      title: "Simon Žnidar",
      pas: "Črni pas, 1. DAN",
      text: "Član kluba",
      imageUrl: simon,
    },
    {
      title: "Luciano Cigale",
      pas: "Črni pas, 1. DAN",
      text: "Član kluba",
      imageUrl: luc,
    },
    {
      title: "Andrej Belas",
      pas: "Črni pas, 1. DAN",
      text: "Član kluba",
      imageUrl: dummy,
    },
      {
      title: "Tilen Jurgec",
      pas: "Črni pas, 1. DAN",
      text: "Član kluba",
      imageUrl: tilen,
    },
      {
      title: "Damjan Max Zorko",
      pas: "Črni pas, 1. DAN",
      text: "Član kluba",
      imageUrl: dummy,
    },
      {
      title: "Dimitrij Gril",
      pas: "Črni pas, 1. DAN",
      text: "Član kluba",
      imageUrl: dummy,
    },
  ]
  return (
    <Layout>
      <Middle title={"Mojstri"} />
      <div class="akc-mojstri">
        {edges ? (
          edges.map(item => {
            return (
              <div class="akc-mojstri-item">
                <div
                  class="mojstri-img"
                  style={{ backgroundImage: `url(${item.imageUrl})` }}
                ></div>
                <div class="mojstri-item-text-upper">
                  <div class="mojstri-upper-box">{item.pas}</div>
                  <h3>{item.title}</h3>
                </div>
                <div class="mojstri-item-text-lower">
                  <h4>{item.text}</h4>
                </div>
              </div>
            )
          })
        ) : (
          <p>Nalagam</p>
        )}
      </div>
    </Layout>
  )
}

export default mojstri
